.w-max-content {
	max-width: max-content !important;
}

.h-70vh {
	height: 70vh;
}
.h-80vh {
	height: 80vh;
}
.h-90vh {
	height: 90vh;
}

.pointer {
	cursor: pointer;
}

.back-blur {
	background-color: #0000004d;
	backdrop-filter: blur(5px);
}

.custom-tooltip {
	--bs-tooltip-max-width: 500px;
}
